import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import styled from "styled-components";

type ButtonProps = AntButtonProps;

const StyledAntButton = styled(AntButton)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: ${({ type }) => (type === "default" || type === undefined) && "var(--ant-color-primary)"};
`;

const Button = (props: ButtonProps) => {
    return <StyledAntButton {...props} />;
};

export default Button;
