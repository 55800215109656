import { isBefore, isSameDay } from "date-fns";
import { last } from "lodash";
import Link from "next/link";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAuthenticatedUserClassrooms } from "@sol/classrooms";
import { interpolate, Route } from "@sol/routing";
import { getResourceUuid } from "@sol/sdk";
import { Arrow } from "@sol/uikit/core/icons";
import Button from "@sol/uikit/general/Button";

import { ClassroomsList } from "./ClassroomsList";
import { HomeCard, HomeCardDescription } from "./HomeCard";

export function LearnerHome() {
    const { classrooms, isLoading } = useAuthenticatedUserClassrooms();
    const [t] = useTranslation();

    const today = useMemo(() => new Date(), []);

    // We filter started or finished classrooms.
    const startedClassrooms = classrooms?.filter(
        c => isBefore(new Date(c.startDate), today) || isSameDay(new Date(c.startDate), today),
    );

    // We sort them from oldest start date to newest start date.
    startedClassrooms?.sort((c1, c2) => {
        return new Date(c2.startDate).getTime() - new Date(c1.startDate).getTime();
    });

    // As classrooms dates cannot overlap the latest one is the one that was just finished or the current one.
    const classroom = last(startedClassrooms ?? []);
    const classroomUUID = classroom ? getResourceUuid(classroom) : undefined;

    return (
        <div className="flex min-h-full w-full flex-row gap-4">
            <div className="flex w-1/2 flex-col gap-4">
                <HomeCard title={t("component.home.learner.classrooms.title")} className="col-span-1 row-span-1">
                    <ClassroomsList isLoading={isLoading} classrooms={classroom ? [classroom] : []} />
                </HomeCard>
                <HomeCard
                    title={t("component.home.learner.briefs.title")}
                    className="min-h-0 flex-1"
                    image={<img src="/static/home/search.svg" alt="" className="object-contain" />}
                >
                    <HomeCardDescription label={t("component.home.learner.briefs.label")}>
                        {t("component.home.learner.briefs.description")}
                    </HomeCardDescription>
                    <Link href={interpolate(Route.CLASSROOM_BRIEFS, { classroomId: classroomUUID })} passHref>
                        <Button
                            disabled={!classroomUUID}
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.learner.briefs.cta.title")}
                            aria-label={t("component.home.learner.briefs.cta.title")}
                        >
                            {t("component.home.learner.briefs.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
                <HomeCard
                    title={t("component.home.learner.work.title")}
                    className="min-h-0 flex-1"
                    image={<img src="/static/home/planning.svg" alt="" className="object-cover object-right-top" />}
                >
                    <HomeCardDescription label={t("component.home.learner.work.label")}>
                        {t("component.home.learner.work.description")}
                    </HomeCardDescription>
                    <Link href={interpolate(Route.CLASSROOM_WORKSPACES, { classroomId: classroomUUID })} passHref>
                        <Button
                            disabled={!classroomUUID}
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.learner.work.cta.title")}
                            aria-label={t("component.home.learner.work.cta.title")}
                        >
                            {t("component.home.learner.work.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
            </div>
            <div className="flex w-1/2 flex-col gap-4">
                <HomeCard title={t("component.home.learner.scenario.title")} image={<div />}>
                    <HomeCardDescription label={t("component.home.learner.scenario.label")}>
                        {t("component.home.learner.scenario.description")}
                    </HomeCardDescription>
                    <Link
                        href={interpolate(Route.CLASSROOM_PEDAGOGICAL_SCENARIO, { classroomId: classroomUUID })}
                        passHref
                    >
                        <Button
                            disabled={!classroomUUID}
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.learner.scenario.cta.title")}
                            aria-label={t("component.home.learner.scenario.cta.title")}
                        >
                            {t("component.home.learner.scenario.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
                <HomeCard
                    title={t("component.home.learner.self-evaluation.title")}
                    className="min-h-0 flex-1"
                    image={<img src="/static/home/analytics-2.svg" alt="" className="object-cover object-right-top" />}
                >
                    <HomeCardDescription label={t("component.home.learner.self-evaluation.label")}>
                        {t("component.home.learner.self-evaluation.description")}
                    </HomeCardDescription>
                    <Link href={interpolate(Route.FOLLOW_UPS_LIST, {})} passHref>
                        <Button
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.learner.self-evaluation.cta.title")}
                            aria-label={t("component.home.learner.self-evaluation.cta.title")}
                        >
                            {t("component.home.learner.self-evaluation.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
                <HomeCard title={t("component.home.learner.resources.title")} image={<div />}>
                    <HomeCardDescription label={t("component.home.learner.resources.label")}>
                        {t("component.home.learner.resources.description")}
                    </HomeCardDescription>
                    <Link
                        href={interpolate(Route.CLASSROOM_RESOURCES, {
                            classroomId: classroomUUID,
                        })}
                        passHref
                    >
                        <Button
                            disabled={!classroomUUID}
                            size="small"
                            className="self-end"
                            iconPosition="end"
                            icon={<Arrow direction="right" stroke="transparent-base" size={16} />}
                            title={t("component.home.learner.resources.cta.title")}
                            aria-label={t("component.home.learner.resources.cta.title")}
                        >
                            {t("component.home.learner.resources.cta.label")}
                        </Button>
                    </Link>
                </HomeCard>
            </div>
        </div>
    );
}
